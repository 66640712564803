import DateHelper from '@/util/DateHelper'

let StatsBaseYearGridMixin = {
  methods: {
    defaultTo: () => DateHelper.firstDayOfYearAsString(),
    defaultFrom: () => DateHelper.firstDayOfYearAsString(DateHelper.getCurrentYear() - 5)
  }
}

export default StatsBaseYearGridMixin
