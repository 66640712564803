<template>
  <div id="stats-domain-year">
    <StatsDomainYear />
  </div>
</template>

<script>
import StatsDomainYear from '../../../components/stats/domain/StatsDomainYear'

export default {
  components: {
    StatsDomainYear
  }
}
</script>
