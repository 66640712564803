<template>
  <v-text-field :label="label" type="number" :value="date" @change="updateDate" class="year-selector" hide-details filled dense />
</template>

<script>
import DateHelper from '../../util/DateHelper'

export default {
  name: 'YearSelector',
  props: {
    label: {
      String,
      required: true
    },
    defaultDate: {
      String,
      required: true
    }
  },
  data() {
    return {
      date: this.$f.shortYear(this.defaultDate)
    }
  },
  methods: {
    updateDate(date) {
      this.date = date
      this.$emit('update_date', date)
    }
  },
  computed: {
    calculatedMinDate() {
      return DateHelper.toStringOrDefaultMinDate(this.minDate)
    }
  }
}
</script>

<style lang="sass"></style>
