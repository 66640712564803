<template>
  <div id="stats-domain-year">
    <Grid
      data-store-action="stats_user_domain_year/fetchData"
      :headers="headers"
      :enable-filters="enableFilters"
      :enable-pagination="enablePagination"
      :default-sort-by="defaultSortBy"
      :default-sort-desc="defaultSortDesc"
      :additional-api-arguments="apiArgs"
      :download-link-arguments="downloadLinkArguments"
      form-name="stats-account-year-form"
      v-if="$route.params.domain"
    >
      <!-- filter slot with additional filter -->
      <template slot="filters" v-if="enableFilters">
        <v-col class="col-12 col-sm-4 col-md-2 filter-date-from">
          <YearSelector
            :label="$t('grid.from')"
            :default-date="apiArgs.persistent.from.value"
            @update_date="updateDate($event, 'from')"
            content-class="filter-date-from-menu"
          />
        </v-col>
        <v-col class="col-12 col-sm-4 col-md-2 filter-date-to">
          <YearSelector
            :label="$t('grid.to')"
            :default-date="apiArgs.persistent.to.value"
            @update_date="updateDate($event, 'to')"
            content-class="filter-date-to-menu"
          />
        </v-col>
      </template>

      <!-- middle slot with bar chart -->
      <template v-slot:middle="{ items }" v-if="apiArgs.persistent.from.value">
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col class="col-12">
                <LineChart
                  :data="items"
                  date-field="year_start"
                  :series="[
                    { data_key: 'revenue', yaxis: true, unit: '$', type: 'line', label: $t('stats.revenue_chart') },
                    { data_key: 'uniques', yaxis: true, yaxis_opposite: true, type: 'line', label: $t('stats.uniques_chart') }
                  ]"
                  :xaxis="[apiArgs.persistent.from.value, apiArgs.persistent.to.value]"
                  xunit="year"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <!-- data table slots -->
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ $f.shortYear(item.year_start) }}</td>
          <td class="text-right">{{ $f.number(item.uniques, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(item.clicks, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(item.ctr) }}%</td>
          <td class="text-right">{{ $f.price(item.rpc, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(item.rpm, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(item.revenue, 'USD', true) }}</td>
        </tr>
      </template>
      <template slot="body.append" v-if="enableSums || enableAvgs">
        <tr class="font-weight-bold" v-if="enableSums">
          <td>{{ $t('grid.total') }}</td>
          <td class="text-right">{{ $f.number(sum.uniques, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(sum.clicks, 0, 0) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right">{{ $f.price(sum.revenue, 'USD', true) }}</td>
        </tr>
        <tr class="font-weight-bold" v-if="enableAvgs">
          <td>{{ $t('grid.avg') }}</td>
          <td class="text-right">{{ $f.number(avg.uniques, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(avg.clicks, 0, 0) }}</td>
          <td class="text-right">{{ $f.number(avg.ctr) }}%</td>
          <td class="text-right">{{ $f.price(avg.rpc, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(avg.rpm, 'USD', true) }}</td>
          <td class="text-right">{{ $f.price(avg.revenue, 'USD', true) }}</td>
        </tr>
      </template>
      <div v-else>
        <router-link :to="{ name: 'domains_index' }">{{ $t('stats_domain_date.no_domain_selected') }}</router-link>
      </div>
    </Grid>
    <v-row class="mt-12 mb-4">
      <v-col class="col-12">
        <v-alert dense outlined class="revenue-delay-info">{{ $t('stats.revenue_delayinfo') }}</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rules } from '@/validation/rules'
import Grid from '@/components/grid/Grid'
import LineChart from '@/components/charts/LineChart'
import YearSelector from '@/components/date/YearSelector'
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import StatsBaseGridMixin from '@/components/stats/mixins/StatsBaseGridMixin'
import StatsBaseYearGridMixin from '@/components/stats/mixins/StatsBaseYearGridMixin'
import StatsBaseDomainGridMixin from '@/components/stats/mixins/StatsBaseDomainGridMixin'
import DateHelper from '@/util/DateHelper'

export default {
  mixins: [GridBaseMixin, StatsBaseGridMixin, StatsBaseDomainGridMixin, StatsBaseYearGridMixin],
  name: 'StatsAccountYear',
  components: {
    Grid,
    LineChart,
    YearSelector
  },
  methods: {
    updateDate($event, type) {
      this.apiArgs.persistent[type].value = DateHelper.firstDayOfYearAsString($event)
    }
  },
  data() {
    return {
      storeName: 'stats_user_domain_year',
      export: {
        fileNamePrefix: 'stats-domain-year',
        apiArgumentsToUse: ['from', 'to', 'domain']
      },
      data: {},
      defaultSortBy: ['year_start'],
      defaultSortDesc: [true],
      rules: { ...rules },
      lastFinishedDate: this.defaultTo(),
      pendingImports: [],
      headers: [
        { text: this.$t('stats.year_start'), value: 'year_start', sortable: true, align: 'start' },
        { text: this.$t('stats.uniques'), value: 'uniques', sortable: false, align: 'right', description: this.$t('stats.uniques_description') },
        { text: this.$t('stats.clicks'), value: 'clicks', sortable: false, align: 'right', description: this.$t('stats.clicks_description') },
        { text: this.$t('stats.ctr'), value: 'ctr', sortable: false, align: 'right', description: this.$t('stats.ctr_description') },
        { text: this.$t('stats.rpc'), value: 'rpc', sortable: false, align: 'right', description: this.$t('stats.rpc_description') },
        { text: this.$t('stats.rpm'), value: 'rpm', sortable: false, align: 'right', description: this.$t('stats.rpm_description') },
        { text: this.$t('stats.revenue'), value: 'revenue', sortable: false, align: 'right', description: this.$t('stats.revenue_description') }
      ],
      xAxisHeadlines: [],
      apiArgs: {
        persistent: {
          from: this.buildArgument('from', from => from !== false, this.defaultFrom()),
          to: this.buildArgument('to', to => to !== false, this.defaultTo()),
          show_empty: this.buildArgument('show_empty', null, 1),
          domain: this.buildArgument('domain', domain => domain !== '')
        }
      }
    }
  }
}
</script>
